import Vue from 'vue'
import VueTypedJs from 'vue-typed-js'
import App from './App.vue'
import Meta from "vue-meta";

import './assets/styles/index.css';
import './assets/styles/custom.css';

Vue.config.productionTip = false

Vue.use(VueTypedJs)
Vue.use(Meta);

new Vue({
  render: h => h(App),
}).$mount('#app')
