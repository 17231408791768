<template>
  <div id="app" class="container relative flex flex-col max-w-screen-md min-h-screen px-6 mx-auto antialiased sm:px-8 md:px-4">
    <div class="mt-16">
      <vue-typed-js @onComplete="complete(0)" :showCursor="false" :strings="['^500Hi Olia,', 'Hi love,^1000']" :backSpeed="50">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <vue-typed-js class="mt-6" v-if="completed[0] == true" :showCursor="false" @onComplete="complete(1)" :strings="['As we say goodbye to 2020,^400 I thought I\'d write you a note.^600 However,^300 before you read it,^300 I want to make sure that you are in a comfortable setting.^600 Once you\'re ready to continue^400 (in other words,^400 once you finish watching Tenet),^400 click the button below.^400']">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <transition name="fade">
        <button @click="complete(2); remove(1)" v-if="completed[1] == true" class="bg-pink-500 text-white py-2 px-4 font-medium mt-8 rounded shadow hover:shadow-md">
          I am ready
        </button>
      </transition>
      <vue-typed-js class="mt-6" v-if="completed[2] == true" :showCursor="false" @onComplete="complete(3)" :strings="['^1000Actually,^400 one more thing :)^600 Light up your Yankee candle,^400 and play <i>one</i> song from <b>I∩O</b>.^600 Again,^400 click the button below once you\'re done.^400']" :contentType="html">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <transition name="fade">
        <button @click="completeInOneSecond(4); remove(3);" v-if="completed[3] == true" class="bg-blue-500 text-white py-2 px-4 font-medium mt-8 rounded shadow hover:shadow-md">
          All set
        </button>
      </transition>
      <transition name="fade">
        <div v-if="completed[4] == true" class="mt-6 border-t-2 border-gray-200 border-opacity-50">
          <vue-typed-js class="mt-6" v-if="completed[4] == true" :showCursor="false" @onComplete="complete(5)" :strings="['^1000For multiple years,^400 I was eagerly waiting for 2020.^600 This has been the year associated with me for the entirety of my Andover experience^400 —^400 Class of 2020.^600 Even my favorite hoodie had MMXX on it.^600 This was supposed to be the year when I would finally get a chance to take a break from studies,^400 spend time with friends,^400 and travel.^600 I would then start off college ^400(I was even lucky to know which one that would be before 2020 started).^600 I knew it would be a transformative year,^400 but I didn\'t know in what way.']">
            <h1 class="typing"></h1>
          </vue-typed-js>
        </div>
      </transition>
      <vue-typed-js class="mt-6" v-if="completed[5] == true" :showCursor="false" @onComplete="complete(6)" :strings="['^1000Just before leaving for the spring break,^400 I asked one of my house counselors for books to read,^400 and one of them was “Fooled by Randomness.”^600 There couldn\'t have been a better time to read it than when Covid-19 just started spreading,^400 because I realized that all my predictions for 2020 were false.^600 As I hopelessly said goodbye to all my aspirations for 2020,^400 I was not really hoping for anything good to happen anymore.']">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <vue-typed-js class="mt-6" v-if="completed[6] == true" :showCursor="false" @onComplete="complete(7)" :strings="['^1000But then there was yet another unexpected event^400 —^400 I met you.^600 You were a black swan.^600 You came unexpectedly,^400 and significantly impacted my life.^600 But you were the good kind:^400 instead of ruining my hopes,^400 you made me happy.^600 Finally,^400 amid this global uncertainty,^400 there is one thing I am certain about.^600 I want to be with you.^600 And I want to navigate the rest of the uncertainty with you.^1000']">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <vue-typed-js class="mt-6" v-if="completed[7] == true" :showCursor="false" @onComplete="complete(8)" :strings="['2020 is our year.^600 But it is only our first year.^600 Here\'s to many more years together.']" :contentType="html">
        <h1 class="typing"></h1>
      </vue-typed-js>
      <div class="flex flex-row mb-16 mt-6" v-if="completed[8]">
        <span class="text-blue-500">I</span>
        <vue-typed-js class="text-gray-300" :showCursor="false" :strings="['∩', '∪', '+', '♡']" :typeSpeed="500" :backSpeed="500" :loop="true">
          <h1 class="typing"></h1>
        </vue-typed-js>
        <span class="text-pink-500">O</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'App',
  data() {
    return {
      completed: [false, false, false, false, false, false, false, false, false]
    }
  },
  methods: {
    complete(n) {
      Vue.set(this.completed, n, true)
    },
    completeInOneSecond(n) {
      setTimeout(() => {
        Vue.set(this.completed, n, true)
      }, 1000)
    },
    remove(n) {
      Vue.set(this.completed, n, false)
    },
    isCompleted(n) {
      return this.completed[n]
    }
  },
  metaInfo() {
    return {
      title: "A note from 2020",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "A short letter to Olia to conclude 2020."
        }
      ]
    };
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
b, strong {
  font-weight: 600 !important;
}
</style>
